import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import {intlShape} from '@ecosio/components';
import {
  Button,
  Grid,
  Header,
  Icon,
  Input as SemanticInput,
  List,
  Loader,
  Message,
  Modal,
  Progress,
  Segment,
} from 'semantic-ui-react';
import {FormattedMessage as Msg, injectIntl} from 'react-intl';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import {
  Form,
  FormGroup,
  Input,
  InputArray,
  ExternalSubmit,
} from '@ecosio/pathform';
import {FORM_ERROR} from 'final-form';
import CategorySelector from './CategorySelector';
import {configurationShape, fieldsShape, customerConfigShape} from './shapes';

const mapStateToProps = ({formData, locales, config}) => ({
  formData,
  locale: locales.locale,
  config,
});

const ContactPersonBlock = ({name, header}) => (
  <React.Fragment>
    <Header as="h3" size="small">
      <Msg id={header} />
    </Header>

    <FormGroup widths="equal">
      <Input name={`${name}.name`} path="companyContacts.name" />
      <Input name={`${name}.telephone`} path="companyContacts.telephone" />
      <Input name={`${name}.fax`} path="companyContacts.fax" />
      <Input name={`${name}.email`} path="companyContacts.email" />
    </FormGroup>
  </React.Fragment>
);

const ErrorMessage = ({error}) => (
  <Message negative>
    <Message.Header>
      <Msg id="SSA_ERROR_HEADER" />
    </Message.Header>

    <p>{error}</p>
  </Message>
);

ErrorMessage.propTypes = {
  error: PropTypes.string.isRequired,
};

ContactPersonBlock.propTypes = {
  name: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
};

const FactsAndFigures = ({configuration, fields}) => {
  return (
    <FormGroup className="f_and_f">
      {Object.keys(fields).map((path, idx) => {
        const unitTranslationKey = configuration.units[path];
        const unit = <Msg id={unitTranslationKey} values={{value: ''}} />;

        return (
          <Input
            key={idx}
            width={4}
            name={`factsAndFigures.${path}`}
            path={path}
            inputLabel={{
              content: unit,
            }}
            labelPosition="right"
          />
        );
      })}
    </FormGroup>
  );
};

FactsAndFigures.propTypes = {
  configuration: configurationShape,
  fields: fieldsShape,
};

const getFactsAndFiguresFields = (configuration) => {
  const visibility = configuration?.fieldVisibility;
  if (!visibility) {
    return {};
  }

  return Object.keys(visibility)
    .filter((k) => visibility[k] === 'BOTH')
    .map((k) => configuration.formFields[k])
    .reduce((acc, next) => Object.assign({}, acc, {[next.path]: next}), {});
};

const convertBadRequestError = (data) => {
  return data.errors.reduce(
    (acc, error) => ({
      ...acc,
      [error.field.replace('supplier.', '')]: 'GENERAL_ERROR',
    }),
    {}
  );
};

@injectIntl
export class SsaFormComponent extends Component {
  static propTypes = {
    locale: PropTypes.string.isRequired,
  };

  state = {
    uuid: uuidv4(),
    attachments: [],
    formConfiguration: null,
    formId: null,
    categories: null,
    offeringCompanyId: null,
    error: null,
    countries: [],
    successfullySubmitted: false,
  };

  componentDidMount() {
    this.fetchFormConfiguration(this.props.config.customerId);
  }

  fetchFormConfiguration = (formId) => {
    axios
      .get(`/api/form/configuration?id=${formId}`)
      .then((response) => {
        const {
          sidebarConfiguration,
          categories,
          offeringCompanyId,
          factsAndFiguresConfiguration,
          countryOptions,
        } = response.data;
        this.setState({
          formConfiguration: sidebarConfiguration,
          categories,
          formId,
          offeringCompanyId,
          factsAndFiguresConfiguration,
          countries: countryOptions,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          error: err.message,
        });
      });
  };

  showModal = () => {
    this.setState({modalVisible: true});
  };

  submitForm = (values) => {
    const {uuid, attachments, formId, offeringCompanyId} = this.state;
    const form = {
      ...values,
      uuid,
      attachments,
      formId,
      offeringCompanyId,
    };

    const factsAndFigures = form.factsAndFigures;

    this.setState({uuid: uuidv4()});
    const supplier = {...form};
    supplier.status = 'PRE_ASSESSMENT_UNDER_REVIEW';

    // delete foreign JSON properties before sending to server
    delete supplier.factsAndFigures;

    const body = {
      supplier,
      factsAndFigures,
    };

    return new Promise((resolve) => {
      return axios
        .post('/api/form', body)
        .then((res) => {
          this.setState({modalVisible: false});
          if (res.status === 200) {
            this.setState({
              successfullySubmitted: true,
            });
          } else {
            console.error('error submitting form', res);
            // eslint-disable-next-line no-alert
            alert('There was an error submitting your form. Please try again.');
            resolve({[FORM_ERROR]: 'ERROR'});
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({modalVisible: false});
          // eslint-disable-next-line no-alert
          alert('There was an error submitting your form. Please try again.');
          const errorData = convertBadRequestError(error.response.data);
          return resolve(errorData);
        });
    });
  };

  doFileUpload = (file) => {
    if (!file) {
      return;
    }
    this.setState({uploading: true, error: null});
    const upload = new window.FormData();
    upload.append('file', file);
    axios
      .post('/api/form/upload', upload, {
        headers: {
          'X-FormId': this.state.uuid,
        },
        onUploadProgress: (event) => {
          this.setState({
            uploadProgress: event.loaded,
            totalSize: event.total,
          });
        },
      })
      .then((res) => {
        this.setState((prevState) => ({
          totalSize: null,
          uploadProgress: null,
          uploading: false,
          attachments: prevState.attachments.concat([res.data]),
        }));
      })
      .catch((error) => {
        console.error(error);
        window.scrollTo(0, 0);
        this.setState({
          error: error.message,
          totalSize: null,
          uploadProgress: null,
          uploading: false,
        });
      });
  };

  onFileChange = (event) => {
    const MAX_SIZE = 5 * 1000 * 1000;

    event.preventDefault();
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (file.size <= MAX_SIZE) {
      this.doFileUpload(event.target.files[0]);
    } else {
      this.removeAttachment(null);

      window.scrollTo(0, 0);
      const error = this.props.intl.formatMessage({id: 'UPLOAD_FILE_TOO_BIG'});

      this.setState({
        error,
      });
    }
  };

  removeAttachment = (attachmentId) => {
    if (attachmentId) {
      this.setState((prevState) => ({
        attachments: prevState.attachments.filter(
          ({uuid}) => uuid !== attachmentId
        ),
      }));
    }

    // FIXME ugly hack.
    const el = document.getElementById('file-upload-input');
    el.value = null;
  };

  onModalClose = () => this.setState({modalVisible: false});

  render() {
    const blockInFieldsObject = (keys, blockName) => {
      return keys.some((f) => f.startsWith(blockName));
    };

    const initialValues = this.props.formData.data;
    const locale = this.props.locale;
    const {
      attachments,
      uploading,
      totalSize,
      uploadProgress,
      modalVisible,
      formConfiguration,
      categories,
      error,
      factsAndFiguresConfiguration,
      countries,
      successfullySubmitted,
    } = this.state;

    if (!formConfiguration) {
      if (error) {
        return <ErrorMessage error={error} />;
      } else {
        return <Loader active />;
      }
    }
    let progressPercent = Math.round((totalSize / uploadProgress) * 100);
    if (isNaN(progressPercent)) {
      progressPercent = null;
    }
    const page = formConfiguration.pages.find(
      (page) => page.type === 'PRE_ASSESSMENT'
    );

    if (!page) {
      console.error(
        'Could not find page configuration with type PRE_ASSESSMENT'
      );
      return <ErrorMessage error={<Msg id="GENERAL_ERROR" />} />;
    }
    const fields = page.fields;

    const factsAndFiguresFields = getFactsAndFiguresFields(
      factsAndFiguresConfiguration
    );
    const allFields = Object.assign({}, fields, factsAndFiguresFields);

    const keys = Object.keys(allFields);
    const hasAddressData = blockInFieldsObject(keys, 'addressData');
    const hasContactData = blockInFieldsObject(keys, 'contactData');
    const hasCompanyContacts = blockInFieldsObject(keys, 'companyContacts');

    const title = this.props.intl.formatMessage({
      id: 'SUPPLIER_SELF_ASSESSMENT',
    });

    const categoriesObj = {
      data: categories,
      rootEl: categories?.find((el) => !el.parent),
    };

    return (
      <React.Fragment>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <Header as="h1" size="large">
          <Msg id="SUPPLIER_SELF_ASSESSMENT" />
        </Header>

        {error && <ErrorMessage error={error} />}

        <Modal open={modalVisible}>
          <Modal.Header>
            <Msg id="GENERAL_PLEASE_CONFIRM" />
          </Modal.Header>
          <Modal.Content>
            <Msg id="SSA_CONFIRM_SEND" />
          </Modal.Content>

          <Modal.Actions>
            <ExternalSubmit formid="ssa-form" positive>
              <Msg id="GENERAL_YES" />
            </ExternalSubmit>
            <Button onClick={this.onModalClose} negative>
              <Msg id="GENERAL_NO" />
            </Button>
          </Modal.Actions>
        </Modal>

        {successfullySubmitted && (
          <Segment>
            <Message positive>
              <Message.Header>
                <Msg id="POST_SSA_MESSAGE_HEADER" />
              </Message.Header>
              <p>
                <Msg id="POST_SSA_MESSAGE" />
              </p>
            </Message>
          </Segment>
        )}

        {!successfullySubmitted && (
          <Form
            formid="ssa-form"
            onSubmit={this.submitForm}
            fields={allFields}
            subscription={{
              invalid: true,
              pristine: true,
              dirtySinceLastSubmit: true,
            }}
            initialValues={initialValues}>
            {({invalid, dirtySinceLastSubmit}) => (
              <Segment.Group>
                <Segment>
                  <Grid divided stackable>
                    <Grid.Row columns={2}>
                      {hasAddressData && (
                        <Grid.Column width={8}>
                          <Header size="medium" as="h2">
                            <Msg id="GENERAL_ADDRESS_DATA" />
                          </Header>
                          <FormGroup>
                            <Input
                              name="contactData.company"
                              path="contactData.company"
                              width={16}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Input
                              name="addressData.street"
                              path="addressData.street"
                              width={13}
                            />
                            <Input
                              name="addressData.streetNumber"
                              path="addressData.streetNumber"
                              width={3}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Input
                              name="addressData.postalCode"
                              path="addressData.postalCode"
                              width={8}
                            />
                            <Input
                              name="addressData.city"
                              path="addressData.city"
                              width={8}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Input
                              name="addressData.region"
                              path="addressData.region"
                              width={8}
                            />
                            <Input
                              name="addressData.country"
                              path="addressData.country"
                              options={countries}
                              search
                              width={8}
                            />
                          </FormGroup>
                        </Grid.Column>
                      )}
                      {hasContactData && (
                        <Grid.Column>
                          <Header size="medium" as="h2">
                            <Msg id="GENERAL_CONTACT_DATA" />
                          </Header>

                          <FormGroup>
                            <Input
                              name="loginEmail"
                              path="loginEmail"
                              width={16}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Input
                              name="contactData.telephone"
                              path="contactData.telephone"
                              width={8}
                            />
                            <Input
                              name="contactData.fax"
                              path="contactData.fax"
                              width={8}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Input
                              name="contactData.website"
                              path="contactData.website"
                              width={16}
                            />
                          </FormGroup>
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </Grid>
                </Segment>

                {hasCompanyContacts && (
                  <Segment>
                    <Header as="h2" size="medium">
                      <Msg id="GENERAL_COMPANY_CONTACTS" />
                    </Header>
                    <InputArray name="companyContacts">
                      {({fields}) =>
                        fields.map((field, idx) => (
                          <ContactPersonBlock
                            key={idx}
                            name={field}
                            header={fields.value[idx].headerTranslationKey}
                          />
                        ))
                      }
                    </InputArray>
                  </Segment>
                )}
                <Segment>
                  <Header as="h2" size="medium">
                    <Msg id="GENERAL_LOCATIONS" />
                  </Header>

                  <FormGroup>
                    <Input
                      className="headquarter"
                      name="countryOfHeadquarters"
                      path="countryOfHeadquarters"
                      options={countries}
                      width={8}
                      search
                    />
                  </FormGroup>
                </Segment>

                <Segment>
                  <Header as="h2" size="medium">
                    <Msg id="GENERAL_PRODUCT_GROUPS" />
                  </Header>

                  <CategorySelector
                    depth={3}
                    name="categoryIds"
                    path="categoryIds"
                    categories={categoriesObj}
                    locale={locale}
                    sort
                    multiple={false}
                  />
                </Segment>

                <Segment>
                  <Header as="h2" size="medium">
                    <Msg id="GENERAL_FACTS_AND_FIGURES_CURRENT_YEAR" />
                  </Header>

                  <FactsAndFigures
                    configuration={factsAndFiguresConfiguration}
                    fields={factsAndFiguresFields}
                  />
                </Segment>

                <Segment>
                  <Grid stackable divided>
                    <Grid.Row columns={1}>
                      <Grid.Column width={6} className="att_wrap">
                        <Header as="h2" size="medium">
                          <Msg id="GENERAL_UPLOAD_ATTACHMENTS" />
                        </Header>
                        {/* TODO move to pathform upload field */}
                        <SemanticInput
                          type="file"
                          onChange={this.onFileChange}
                          id="file-upload-input"
                          className="att_input"
                        />
                        <List>
                          {attachments.map(({originalFileName, uuid}) => (
                            <List.Item key={uuid}>
                              <List.Content floated="right">
                                <Button
                                  onClick={() => this.removeAttachment(uuid)}
                                  type="button"
                                  icon="remove"
                                  size="small"
                                  compact
                                  negative
                                />
                              </List.Content>

                              <List.Icon name="file outline" />
                              <List.Content>{originalFileName}</List.Content>
                            </List.Item>
                          ))}
                        </List>
                        {progressPercent && (
                          <Progress
                            progress
                            percent={progressPercent}
                            autoSuccess
                          />
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
                <Segment clearing>
                  <Button
                    type="button"
                    floated="right"
                    positive
                    disabled={uploading || (invalid && !dirtySinceLastSubmit)}
                    onClick={this.showModal}>
                    <Icon name="checkmark" />
                    &nbsp;
                    <Msg id="GENERAL_SUBMIT_FORM" />
                  </Button>
                </Segment>
              </Segment.Group>
            )}
          </Form>
        )}
      </React.Fragment>
    );
  }
}

SsaFormComponent.propTypes = {
  formData: PropTypes.object,
  intl: intlShape,
  config: customerConfigShape,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const SsaForm = connect(mapStateToProps)(SsaFormComponent);
export default SsaForm;
