import React from 'react';
import {Provider} from 'react-redux';
import configureStore, {history} from './store';
import {AppRouter} from './AppRouter';

const store = configureStore();

const App = () => (
  <Provider store={store}>
    <AppRouter history={history} />
  </Provider>
);

export default App;
