import {createStore, applyMiddleware} from 'redux';
import {thunk} from 'redux-thunk';
import {createBrowserHistory} from 'history';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import rootReducer from './reducers';

export const history = createBrowserHistory();

const routingMiddleware = routerMiddleware(history);

const configureStore = (initialState = {}) =>
  createStore(
    connectRouter(history)(rootReducer),
    initialState,
    applyMiddleware(thunk, routingMiddleware)
  );

export default configureStore;
